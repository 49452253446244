@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    /* Disables pull-to-refresh but allows overscroll glow effects. */
    overscroll-behavior-y: contain;
    overflow-y: hidden;
    height: 100%;
}